import prismicConfig from "../../prismic-demo/config/prismic";
import { sharedCSPDirectives } from "config/utils";

export default {
  default_image_url: "https://placehold.it/150x220",
  available_page_sizes: [16, 16 * 2, 16 * 4],
  mapsKey: "AIzaSyB451F91Eed54R7ToFu8_tzH-0IMyN66YI",
  website_id: 1,
  tax: 1,
  contentSecurityPolicy: {
    // see https://developer.akamai.com/tools/boomerang#content-security-policy-(csp) for mPulse
    directives: sharedCSPDirectives({
      scriptSrc: [
        "https://*.go-mpulse.net",
        "checkout-sdk.bigcommerce.com",
        ...prismicConfig.csp.scriptSrc,
      ],
      frameSrc: [
        "front-commerce.mybigcommerce.com",
        ...prismicConfig.csp.frameSrc,
      ],
      styleSrc: [...prismicConfig.csp.styleSrc],
      imgSrc: [
        "*.front-commerce.com",
        "https://*.akstat.io",
        "*.bigcommerce.com",
        ...prismicConfig.csp.imgSrc,
      ],
      connectSrc: [
        "https://*.akstat.io",
        "https://*.go-mpulse.net",
        ...prismicConfig.csp.connectSrc,
      ],
      baseUri: [...prismicConfig.csp.baseUri],
    }),
  },
  homepage_identifier: "home",
  defaultTitle: "Front-Commerce Big Commerce",
  defaultDescription:
    "Demo website featuring Front-Commerce using Big Commerce as a backend.",
  themeColor: "#666699",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [
      "description",
      "name",
      "image",
      "status",
      "tax_class_id",
    ],
    authorizedCategoriesFacet: [],
  },
  phoneNumber: "05 61 40 54 92",
  email: "contact@front-commerce.com",
  rewrittenToRoot: ["/home"],
  useUserTitles: false,
};

import React from "react";
import PushBlock from "theme/components/organisms/PushBlock/PushBlock";
import Grid from "theme/components/templates/Grid/Grid";
import Cell from "theme/components/templates/Grid/Cell";

import { H2 } from "theme/components/atoms/Typography/Heading";
import Link from "theme/components/atoms/Typography/Link";

const Push = ({ data }) => {
  return (
    <div data-demo="BlogPost / Slice / Push" className="container">
      <Grid>
        {data.blocks.map((block, index) => (
          <Cell size={block.cellSize} key={index}>
            <PushBlock imageSrc={block.image} format={block.format}>
              <H2>{block.title}</H2>
              {block.cta.text && (
                <Link buttonAppearance="default" to={block.cta.url || "#"}>
                  {block.cta.text}
                </Link>
              )}
            </PushBlock>
          </Cell>
        ))}
      </Grid>
    </div>
  );
};

export default Push;

import React from "react";
import { Helmet } from "react-helmet-async";
import Link from "theme/components/atoms/Typography/Link";
import ErrorBoundary from "theme/components/helpers/ErrorBoundary";
import Box from "theme/components/atoms/Layout/Box";

import SharedSlicesZone from "theme/modules/Slices/Shared";
import PrismicEmbed from "theme/modules/Prismic/PrismicEmbed";

const BlogPost = ({ post }) => {
  return (
    <>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>

      <Layout>
        <h1 data-demo="BlogPost / title" className="text-4xl">
          {post.title}
        </h1>

        <div data-demo="BlogPost / poster (Embed Field)">
          <PrismicEmbed content={post.poster} />
        </div>

        <SharedSlicesZone
          title={"test"}
          content={post.mainContent}
          renderSlices={(slices) => (
            <div className="space-y-6">{slices.map((slice) => slice)}</div>
          )}
        />
      </Layout>
    </>
  );
};

const Layout = ({ children }) => {
  return (
    <ErrorBoundary>
      {({ hasError }) => {
        return hasError ? (
          <Box>We're sorry but we couldn't properly load the blog post.</Box>
        ) : (
          <div className="blog-post space-y-6 py-20 max-w-2xl mx-auto">
            <Link to="/blog" appearance="text">
              <span className="opacity-75 text-lg">← back to list</span>
            </Link>

            {children}
          </div>
        );
      }}
    </ErrorBoundary>
  );
};
BlogPost.Layout = Layout;

const Skeleton = () => {
  return (
    <BlogPost.Layout>
      <div className="space-y-6">
        <div className="h-10 w-full skeleton" />
        <div className="h-10 w-full skeleton" />
        <div className="h-60 w-full skeleton" />
      </div>
    </BlogPost.Layout>
  );
};
BlogPost.Skeleton = Skeleton;

export default BlogPost;

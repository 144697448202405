import loadable from "@loadable/component";

const DefaultWysiwyg = loadable(() =>
  import("theme/modules/WysiwygV2/DefaultWysiwyg")
);

const typenameMap = {
  MagentoWysiwyg: loadable(() =>
    import("theme/modules/WysiwygV2//MagentoWysiwyg/MagentoWysiwyg")
  ),
  PrismicWysiwyg: loadable(() =>
    import("theme/modules/WysiwygV2/PrismicWysiwyg")
  ),
};

const getWysiwygComponent = (type) => {
  return typenameMap[type] || DefaultWysiwyg;
};

export default getWysiwygComponent;

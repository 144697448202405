const embedScripts = [
  "*.spotify.com",
  "*.twitter.com",
  "*.instagram.com",
  "*.facebook.net",
  "*.facebook.com",
  "*.flickr.com",
  "*.staticflickr.com",
  "*.iamcal.com",
  "*.youtube.com",
  "www.youtube-nocookie.com",
];

const csp = {
  scriptSrc: [
    "prismic.io",
    "static.cdn.prismic.io",
    "https://html2canvas.hertzen.com/dist/html2canvas.min.js",
    "https://cdn.tailwindcss.com",
    ...embedScripts,
  ],
  frameSrc: ["*.prismic.io", ...embedScripts],
  styleSrc: [],
  imgSrc: [...embedScripts],
  connectSrc: [],
  baseUri: [],
};

const routes = [/^\/$/, /\/blog/];

const config = {
  csp,
  routes,
  matchRoute: (pathname) => routes.some((path) => path.test(pathname)),
};

export default config;

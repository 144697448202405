import React, { useState } from "react";
import LoginForm from "theme/modules/User/LoginForm";
import { ErrorAlert } from "theme/components/molecules/Alert";
import Stack from "theme/components/atoms/Layout/Stack";
import Button from "theme/components/atoms/Button";
import RegisterForm from "theme/modules/User/RegisterForm";
import RedirectToRegister from "theme/modules/User/LoginForm/RedirectToRegister";
import LoginHint from "../LoginHint";

const LoginModalContent = ({ message, onLoginSuccess }) => {
  const [displayedForm, setDisplayedForm] = useState("login");
  const [currentData, setCurrentData] = useState({
    email: "",
  });

  return (
    <Stack size="2">
      <ErrorAlert>{message}</ErrorAlert>

      {displayedForm === "login" ? (
        <LoginForm
          onLoginSuccess={onLoginSuccess}
          onChange={(data) => setCurrentData(data)}
          value={{ email: currentData.email }}
          additionalActions={
            <RedirectToRegister
              renderLink={({ children }) => {
                return (
                  <Button
                    appearance="link"
                    onClick={() => {
                      setDisplayedForm("register");
                    }}
                  >
                    {children}
                  </Button>
                );
              }}
            />
          }
        />
      ) : (
        <RegisterForm
          value={{ email: currentData.email }}
          onChange={setCurrentData}
          onLoginSuccess={onLoginSuccess}
        />
      )}
      <LoginHint />
    </Stack>
  );
};

export default LoginModalContent;

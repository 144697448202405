import React from "react";
import PrismicPage from "theme/pages/PrismicPage/PrismicPage";
import BlogPost from "theme/pages/Blog/Post";

export const dispatchedRoutes = {
  PrismicPage: (props) => {
    return <PrismicPage {...props} />;
  },
  BlogPost: ({ loading, matched }) => {
    if (loading) {
      return <BlogPost.Skeleton />;
    }
    if (!matched) {
      return <BlogPost.Layout>Blog post not found!</BlogPost.Layout>;
    }
    return <BlogPost post={matched} />;
  },
};

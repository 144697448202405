import React, { useRef, useEffect } from "react";

// see https://github.com/christo-pr/dangerously-set-html-content/blob/main/libs/dangerously-set-html-content/src/lib/dangerously-set-html-content.tsx

/**
 * @param {React.HTMLAttributes<HTMLDivElement> & {html:string}} props
 */
const InjectHtml = ({ html, ...props }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can not be null");

    const slotHtml = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = "";
    divRef.current.appendChild(slotHtml);
  }, [html, divRef]);

  return <div {...props} ref={divRef} />;
};

export default InjectHtml;

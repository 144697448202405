import React from "react";
import FeaturedProducts from "theme/modules/ProductList/FeaturedProducts";
import Section from "theme/components/organisms/Section";
import { H2 } from "theme/components/atoms/Typography/Heading";

import { FEATURED_PRODUCTS_IMAGE_SIZES } from "theme/pages/Home/HomeConstants";

const FeaturedProductsLocal = ({ data }) => {
  if (!data.category) {
    return <h1>Category not found!</h1>;
  }
  return (
    <div data-demo="Slice / Featured Products (Integration Field)">
      <Section title={<H2>{data.title}</H2>}>
        <FeaturedProducts
          categoryId={data.category.id}
          imageSizes={FEATURED_PRODUCTS_IMAGE_SIZES}
          prioritizeTop={0}
        />
      </Section>
    </div>
  );
};

export default FeaturedProductsLocal;

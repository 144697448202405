import React from "react";
import Section from "theme/components/organisms/Section";
import { H2 } from "theme/components/atoms/Typography/Heading";

import ProductListSlider from "theme/modules/ProductList/ProductListSlider";

import { FEATURED_PRODUCTS_IMAGE_SIZES } from "theme/pages/Home/HomeConstants";

const ProductsList = ({ data }) => {
  if (!data.products?.length) {
    return <h1>No Products Selected!</h1>;
  }
  return (
    <div data-demo="Slice / Products (Integration Field)">
      <Section title={<H2>{data.title}</H2>}>
        <ProductListSlider
          length={data.products.length}
          numberOfProductsPerSlide={data.products.length}
          products={data.products}
          imageSizes={FEATURED_PRODUCTS_IMAGE_SIZES}
        />
      </Section>
    </div>
  );
};

export default ProductsList;

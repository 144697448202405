import React from "react";
import Stack from "theme/components/atoms/Layout/Stack";
import SharedSlicesZone from "theme/modules/Slices/Shared";
import { Helmet } from "react-helmet-async";
import WysiwygV2 from "theme/modules/WysiwygV2";

const PrismicPage = (props) => {
  if (props.loading) {
    return <div>Loading...</div>;
  }
  if (!props.matched) {
    return <div>No Match</div>;
  }

  return (
    <>
      <Helmet>
        <title>{props.matched.title}</title>
      </Helmet>

      <div className="container">
        <WysiwygV2 content={props.matched.topContent} />
      </div>
      <SharedSlicesZone
        content={props.matched.mainContent}
        renderSlices={(slices) => <Stack size="8">{slices}</Stack>}
      />
    </>
  );
};

export default PrismicPage;

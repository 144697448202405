import React from "react";
import WysiwygV2 from "theme/modules/WysiwygV2";

const Text = ({ data }) => {
  if (!data.content) {
    return null;
  }
  return (
    <div data-demo="Slice / Text (RichText Field)">
      <WysiwygV2 content={data.content} />
    </div>
  );
};

export default Text;

const MAIN_URL =
  process.env.FRONT_COMMERCE_CLOUD_URL ||
  process.env.FRONT_COMMERCE_URL ||
  "http://localhost:4000";

module.exports = {
  en: {
    channelName: "EN",
    locale: "en-GB",
    url: MAIN_URL,
    currency: "GBP",
    default_country_id: "GB",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  fr: {
    channelName: "FR",
    locale: "fr-FR",
    url: MAIN_URL + "/fr",
    currency: "EUR",
    default_country_id: "FR",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
};

import React from "react";
import { FormattedMessage } from "react-intl";

export default (props) => (
  <div className="demo-hint">
    <p>
      <FormattedMessage
        id="molecules.User.LoginForm.LoginHint.label"
        defaultMessage="To test the login and checkout feature, you can use the following credentials
          email:"
      />
    </p>
    <ul className="demo-hint__credentials">
      <li>
        <FormattedMessage
          id="molecules.User.LoginForm.LoginHint.email"
          defaultMessage="Email:"
        />
        &nbsp;
        <span className="demo-hint__selectable-value">
          roni_cost@example.com
        </span>
      </li>
      <li>
        <FormattedMessage
          id="molecules.User.LoginForm.LoginHint.password"
          defaultMessage="Password:"
        />
        &nbsp;
        <span className="demo-hint__selectable-value">
          roni_cost3@example.com
        </span>
      </li>
    </ul>
  </div>
);

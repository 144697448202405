import Push, { PushFragment } from "./Push";
import FeaturedProducts, { FeaturedProductsFragment } from "./FeaturedProducts";
import Carousel, { CarouselFragment } from "./Carousel";
import ProductsList, { ProductsListFragment } from "./ProductsList";
import Text, { TextFragment } from "./Text";
import CustomEmbed, { CustomEmbedFragment } from "./CustomEmbed";

import makeSliceLibrary from "theme/modules/Prismic/Slices/makeSliceLibrary";

const sliceLibrary = makeSliceLibrary("SharedSlice", [
  { component: Push, fragment: PushFragment },
  { component: FeaturedProducts, fragment: FeaturedProductsFragment },
  { component: Carousel, fragment: CarouselFragment },
  { component: ProductsList, fragment: ProductsListFragment },
  { component: Text, fragment: TextFragment },
  { component: CustomEmbed, fragment: CustomEmbedFragment },
]);

const definitions = sliceLibrary.fragmentDefinitions;

export { definitions };

export default sliceLibrary.SliceZone;

import React from "react";
import InjectHtml from "theme/components/atoms/InjectHtml";

const CustomEmbed = ({ data }) => {
  if (!data.html) {
    return null;
  }
  return <InjectHtml data-demo="Slice / CustomEmbed" html={data.html} />;
};

export default CustomEmbed;

import React from "react";
import MegaHeading from "theme/components/atoms/Typography/Heading/Mega";
import Carousel from "theme/components/organisms/Carousel";
import { CAROUSEL_IMAGE_SIZES } from "theme/pages/Home/HomeConstants";

const CarouselLocal = ({ data }) => {
  const slides = data.slides.map((slide, index) => ({
    key: index,
    title: (
      <MegaHeading as={index === 0 ? "h1" : "h2"}>{slide.title}</MegaHeading>
    ),
    to: slide.cta.url,
    linkTitle: slide.cta.text,
    imageSrc: slide.image,
    priority: true,
  }));

  return (
    <div data-demo="Slice / Carousel">
      <Carousel slides={slides} imageSizes={CAROUSEL_IMAGE_SIZES} />
    </div>
  );
};

export default CarouselLocal;
